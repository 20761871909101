import { useState } from "react";
import { Row, Container, Button, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import Show from "../../../../components/ShowIf";
import TokenInput from "../../../../components/TokenInput";

/**
 * 
 * @param {{
 *     show: boolean
 *     onSend: Function
 *     onReenviar: Function
 *     email: String
 *     voltar: boolean
 * }} props 
 * @returns 
 */
const VerificaToken = props => {
    const [token, setToken] = useState('');
    const TOKEN_SIZE = 6;

    const handleStep = () => {        
        // Valida tamanho do token
        if(!token || token.length != TOKEN_SIZE) return toast.warning(`Por favor, preencha todos os ${TOKEN_SIZE} campos do código`);

        // Direciona para o proximo passo 
        props.onSend(token);
    }
    
    const reenviarToken = (event) => {
        // Impede envio
        event.preventDefault();
        event.stopPropagation();
        
        // Reenvia token
        props.onReenviar();
        return toast.warning("Token reenviado para seu e-mail, por favor verifique.");
    } 

    const goBack = () => {
        props.voltar(1);
    }    

    return (
        <Show if={props.show}>

            <Container className="mainContent">
                <Row>
                    <Col xs>
                        <h1>Por favor, informe o código que enviamos para seu email</h1>
                        <Show if={props.email}>
                            <p className="mb-6 menor text-center">Código enviado para {props.email}</p>
                        </Show>
                    </Col>
                </Row>

                <Row className="mb-5">
                    <Col className="text-center">
                        <TokenInput size={TOKEN_SIZE} onTokenReady={setToken} />
                    </Col>
                </Row>

                <Row className="mb-6 justify-content-md-center">
                    <a href="" onClick={reenviarToken}>Clique aqui para reenviar</a>
                </Row>

                <Row className="justify-content-md-center">
                    <Col md="auto" className="text-center">
                        <Button variant="primary" type="button" onClick={handleStep}>Enviar</Button>
                    </Col>
                    <Col md="auto" className="text-center">
                        <Button variant="primary white fluid-slim no-shadow" type="button" onClick={goBack}>Voltar</Button>
                    </Col>
                </Row>

            </Container>
            
        </Show>
    );
}

export default VerificaToken;