import axios from "axios";
import Auth from "../auth";
import config from "../config.json";

const apiEmissao = axios.create({
    baseURL: config.apiFrontEmissao,
    headers: { 
        'Content-Type': 'application/json'
    }
});

// Adiciona token
apiEmissao.interceptors.request.use(function (config) {
    // console.log(config);

    // Verifica se token existe
    const sessionData = Auth.getSession();
    if(sessionData) config.headers.Authorization = sessionData.token;

    return config;
});

export default apiEmissao;