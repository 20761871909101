import { useEffect, useState } from "react";
import { Navbar, Nav, Container, Dropdown, Figure } from "react-bootstrap";
import { Link, useHistory, useLocation } from "react-router-dom";
import Auth from "../../auth";
import Show from "../ShowIf";

// Assets
import Logo from '../../assets/logo.svg';
// import MenuIcon from '../../assets/icons/menu.png';
// import MenuXIcon from '../../assets/icons/x-menu.png';

const Menu = () => {

    const [sessionData, setSessionData] = useState(false);
    const [iconIsClose, setIconIsClose] = useState(true);
    const history = useHistory();
    let location = useLocation();

    useEffect(()=> {
        setSessionData(Auth.getSession());
    }, [location]);

    const { innerWidth: width } = window;

    const handleLogoff = () => {
        Auth.destroySession();
        history.push('/login');
    }

    return (
        <Navbar collapseOnSelect bg="light" expand="lg" className="menu-principal" id="top">
            <Container className="container-menu">
                <Show if={!sessionData}>
                    <Nav.Link className="navbar-brand" href="https://www.emotion.com.br">
                        <Figure>
                            <img src={Logo} />
                        </Figure>
                    </Nav.Link>
                </Show>

                {/* Logado */}
                <Show if={sessionData}>
                    <Nav.Link className="navbar-brand" href="/dashboard">
                        <Figure><img src={Logo} /></Figure>
                    </Nav.Link>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setIconIsClose(!iconIsClose)}>
                        <span className="color-mainFont font-bold">{sessionData.name}</span>
                    </Navbar.Toggle>
                    <Navbar.Collapse className="menu-right" id="responsive-navbar-nav">
                        <Nav>
                            <Show if={width <= 991}>
                                <div className="mob-opcoes">
                                    <Nav.Link href="/profile">Alterar senha</Nav.Link>
                                    <Nav.Link onClick={handleLogoff}>Logout</Nav.Link>
                                </div>
                            </Show>

                            {/* Menu Tablet/Desktop */}
                            <Show if={width > 991}>
                                <Dropdown drop={'down'}  navbar={true} onToggle={(isOpen) => setIconIsClose(!isOpen)}>
                                    <Dropdown.Toggle id="dropdown-menu">
                                        <span className="color-mainFont font-bold menu-titulo">{sessionData.name}</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="dropdown-menu-container">
                                        <Nav.Link href="/profile">Alterar senha</Nav.Link>
                                        {/* <Dropdown.Divider /> */}
                                        <Nav.Link onClick={handleLogoff}>Logout</Nav.Link>
                                    </Dropdown.Menu>
                                </Dropdown> 
                            </Show>
                        </Nav>
                    </Navbar.Collapse>
                </Show>

            </Container>
        </Navbar>
    );
}

export default Menu;