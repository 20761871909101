import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useLogged from "../../hooks/useLogged";
import { Container, Form, Row, Col, FloatingLabel, Button, Spinner } from "react-bootstrap"
import { useHistory } from "react-router";
import apiAuth from "../../services/apiAuth";
import Show from "../../components/ShowIf";
import TokenInput from "../../components/TokenInput";

const Profile = () => {
    const sessionData = useLogged("/login", false);

    const [dados, setDados] = useState({});
    const [loading, setLoading] = useState(false);
    const [stepForgetPassword, setStepForgetPassword] = useState({status: "0"});
    const [typeEyePassword, setTypeEyePassword] = useState(0);
    const [token, setToken] = useState('');
    const [validated, setValidated] = useState(false);
    const TOKEN_SIZE = 6;
    const history = useHistory();
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    let timeToast = 0; 
    
    useEffect( async () => {
        try {
            setLoading(true);    
            const response = await apiAuth.post('/passwordRecovery/create', { documentNumber: sessionData.documentNumber });

            setLoading(false);    
            if (response.status == 200) {
                forgetPassword("0");                   
            } else {
                controlToast("Não é possível alterar sua senha no momento. Por favor, tente novamente mais tarde");
                history.push("/dashboard");
            }    
        } catch(err) {
            setLoading(false);
            controlToast("Não é possível alterar sua senha no momento. Por favor, tente novamente mais tarde");
            history.push("/dashboard");
        }
    }, []);

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setDados({...dados, [name]: value});
    }  
    
    const forgetPassword = (value) => {
        setStepForgetPassword({status: value});
    }    
    
    const clearToken = () => {
        for( let i = 0; i < TOKEN_SIZE; i++ ) {
            document.getElementById("token-char-"+i).value = "";
        }
        setToken("");
    }
    
    const validateCode = async (event) => {     
        try {
            setLoading(true);

            // Impede envio
            event.preventDefault();
            event.stopPropagation();    
            
            // Valida tamanho do token
            if (!token || token.length != TOKEN_SIZE) {
                setLoading(false);
                clearToken();
                return controlToast(`Por favor, preencha todos os ${TOKEN_SIZE} campos do código`);
            } 

            const response = await apiAuth.post('/passwordRecovery/step10', { 
                documentNumber: sessionData.documentNumber,
                entryCode: token
            });

            setLoading(false);    
            if (response.status === 200) {
                forgetPassword("1");                   
            } else {
                clearToken();
                if (response.status.message) {
                    controlToast(response.status.message);
                } else {
                    controlToast("Não foi possível validar o código. Por favor, tente novamente mais tarde");
                }
            }    
        } catch(err) {
            setLoading(false);
            clearToken();
            controlToast("Não foi possível validar o código. Por favor, tente novamente mais tarde");            
        }
    }        

    const alterPassword = async (event) => {     
        try {
            setLoading(true);
            const form = event.currentTarget;
            setValidated(true);

            // Impede envio
            event.preventDefault();
            event.stopPropagation();

            if (form.checkValidity() === false || validatePassword(dados.newPassword) === false) {
                setLoading(false);                
                document.getElementById('floatingPass').classList.add("manual-error");
                return controlToast("Por favor, preencha corretamente o campo NOVA SENHA");
            } else {
                document.getElementById('floatingPass').classList.remove("manual-error");
            }           
            
            const response = await apiAuth.post('/passwordRecovery/step20', { 
                documentNumber: sessionData.documentNumber,
                password: dados.newPassword
            });

            setLoading(false);
            if (response.status == 200) {
                toast.success("Senha alterada com sucesso!");
                history.push("/dashboard");
            } else {
                if (response.status.message) {
                    controlToast(response.status.message);
                } else {
                    controlToast("Não foi possível salvar a nova senha. Por favor, tente novamente mais tarde");
                }                
            }    
        } catch(err) {
            setLoading(false);
            controlToast("Não foi possível salvar a nova senha. Por favor, tente novamente mais tarde");
        }
    }

    const validatePassword = (values) => {
        return strongRegex.test(values);
    }    
    
    const controlToast = (msg) => {
        if (timeToast == 0) {
            // contrl. para o mesmo toast ñ ser exibido mais de uma vez
            timeToast = 1;
            setTimeout(() => timeToast = 0, 5000);            
            return toast.warning(msg);
        } else {
            return true;
        }        
    }    

    const eyePassword = () => {
        let eye = document.querySelector('.lnr-eye');
        let input = document.querySelector('.eye-pass');        

        if (typeEyePassword === 0) {
            setTypeEyePassword(1);
            input.setAttribute('type', 'text');
            
            eye.classList.add("icon-eye-close");        
            eye.classList.remove("icon-eye-open");
        } else {
            setTypeEyePassword(0);
            input.setAttribute('type', 'password');

            eye.classList.add("icon-eye-open");        
            eye.classList.remove("icon-eye-close");
        }     
    }    

    return (
        <Container className="mainContent">
            <Show if={stepForgetPassword.status === "0"}>
                <h1 className="mb-6">Por favor, informe o código que enviamos para seu email</h1>
                <Form noValidate onSubmit={validateCode}>
                    <Row className="justify-content-md-center text-center">
                        <Col className="text-center mb-5">
                            <TokenInput size={TOKEN_SIZE} onTokenReady={setToken} />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center text-center">
                        <Col md="3">
                            <Button variant="primary fluid" type="submit" disabled={loading}>                            
                                {loading ? <Spinner animation="grow" /> :  "Enviar" }
                            </Button>
                        </Col>
                    </Row>
                </Form>                
            </Show>

            <Show if={stepForgetPassword.status === "1"}>
                <h1 className="mb-6">Alterar senha</h1>
                <Form noValidate validated={validated} onSubmit={alterPassword}>
                    <Row className="justify-content-md-center text-center">
                        <Col md="6">
                            <FloatingLabel controlId="floatingPass" label="Nova senha" className="col-md-12 mb-5">
                                <Form.Control autoComplete="off" required type="password" name="newPassword" className="eye-pass" placeholder="Nova senha" onChange={handleInputChange} />
                                <span onClick={eyePassword} class="icon-eye-open lnr-eye"></span>

                                <p className="legenda">
                                    Mínimo 8 caracteres, contendo:
                                    <ul>
                                        <li>Uma letra maiúscula;</li>
                                        <li>Uma minúscula;</li>
                                        <li>Um número;</li>
                                        <li>Um caracter especial</li>
                                    </ul>
                                </p>                                
                            </FloatingLabel>
                        </Col>
                    </Row>                    

                    <Row className="justify-content-md-center text-center">
                        <Col md="4">
                            <Button variant="primary" type="submit" disabled={loading}>                            
                                {loading ? <Spinner animation="grow" /> :  "Salvar" }
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Show>
        </Container>
    );
}

export default Profile;