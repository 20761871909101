import { useEffect, useState } from "react";
import { Row, Col, Figure } from "react-bootstrap";
import { toast } from "react-toastify";
import useLogged from "../../hooks/useLogged";
import apiEmissao from "../../services/apiEmissao";
import Seguro from "./components/Seguro";
import { primeiroNome } from "../../utils";

// Assets
import GooglePlayPNG from '../../assets/google-play.png';
import AppleStorePNG from '../../assets/app-store.png';

const Dashboard = () => {
    const [policies, setPolicies] = useState([]);
    const sessionData = useLogged("/login", false);

    useEffect(async ()=>{
        try{
            const response = await apiEmissao.post('/policy/getPolicies', {documentNumber: sessionData.documentNumber});
            if(response.status == 200){                
                setPolicies(response.data.data.policies);    
            }else{
                toast.warning("Não foi possível recuperar emissões. Por favor, tente novamente mais tarde");
            }
        } catch(err) {
            if (sessionData) {
                toast.warning("Não foi possível recuperar emissões. Por favor, tente novamente mais tarde");
            } else {
                toast.warning("Por favor, efetue o login");
            }            
        }
    },[]);

    const buildSeguros = () => {
        return policies.map((p,i) => <Seguro key={"seguro-"+i} dados={p} documentNumber={sessionData.documentNumber} />)
    }

    return (
        <section>            
            <div className="d-inline-block">
                <h1 className="mb-6 text-left anim-writing anim-typing">Bem vindo, {primeiroNome(sessionData?.name)}.</h1>
            </div>
            <Row className="justify-content-between">
                <Col sm="12" md="8">
                    { buildSeguros() }                 
                </Col>
                <Col sm="12" md="4" className="ps-5 ps-xs-default menor mb-5">
                    <p>Acesse o app para utilizar o <b>Stories</b> e ter acesso a todos os <b>detalhes do seu seguro</b>.</p>
                    <div className="apps mb-4">
                        <a href="https://apps.apple.com/br/app/emotion-seguros/id1590052290" target="_blank">
                            <Figure className="figure-stores mb-0">
                                <img src={AppleStorePNG} alt="Google Play"/>
                            </Figure>
                        </a>    
                        <a href="https://play.google.com/store/apps/details?id=br.com.emotion.stories" target="_blank">
                            <Figure className="figure-stores mb-0">
                                <img src={GooglePlayPNG} alt="Apple Store"/>
                            </Figure>
                        </a>    
                    </div>

                    <p className="pb-0">Caso tenha alguma dúvida entre em contato pelos nossos canais de atendimento.</p>
                    <ul className="no-disc">
                        <li><strong>WhatsApp: </strong><a href="https://wa.me/551138620085" target="_blank">11 3862-0085</a></li>
                        <li><strong>Telefone: </strong><a href="tel:0800-721-8508" target="_blank">0800 721 8508</a></li>      
                        <li><strong>E-mail: </strong><a href="mailto:meajuda@emotion.com.br" target="_blank">meajuda@emotion.com.br</a></li>
                    </ul>                   
                </Col>
            </Row>
        </section>
    );
}

export default Dashboard;