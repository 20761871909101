import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Container, Col, Figure } from "react-bootstrap";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { useLocation } from "react-router-dom";
import Show from "../../components/ShowIf";
import useInterval from "../../hooks/useInterval";
import lottie from "lottie-web/build/player/lottie_light";
import { toast } from "react-toastify";

import apiEmissao from "../../services/apiEmissao";
import { idadeCompleta, primeiroNome } from "../../utils";

// Assets
import GooglePlayPNG from '../../assets/google-play.png';
import AppleStorePNG from '../../assets/app-store.png';

import LoadingGuido from '../../assets/animations/loading-guido2.json';
import GuidoFloating from '../../assets/animations/guido-floating.json';

/**
 * 
 * @param {{
 *     show: boolean
 *     onSend: Function
 *     onReenviar: Function
 * }} props 
 * @returns 
 */
const DadosContrataco = props => {
    const [policy, setPolicy] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [count, setCount] = useState(1);
    const history = useHistory();
    const { state } = useLocation();

    // =====================================================
    // Auxiliares
    // =====================================================
    const WAIT_TIME = 10000;
    const MAX_ATTEMPS = 4;
    const cpfLimpo = state?.cpf.replace(/[^\d]/g, '');
    let requestEnd = true;

    useEffect(() => {
        if(!state || !state?.cpf) history.push("/");
        
        // exibir tela ancorando no topo
        const view = state?.anchor;
        if (view) {
            const anchor = document.getElementById('top');
            anchor.scrollIntoView();
        }

        lottie.loadAnimation({
            container: document.querySelector("#loading-animation"),
            animationData: LoadingGuido,
            renderer: 'svg',
            loop: true,
            autoplay: true,
        });
        
    }, []);

    useInterval(async () => {
        try{
            if(requestEnd){

                requestEnd = false;
    
                const response = await apiEmissao.post('/insurance/getInsurance', {
                    documentNumber: cpfLimpo,
                    productCode: "0001"
                });
    
                requestEnd = true;
                
                // Valida response
                if(response.status == 200 && response.data){
                    successPolicy(response.data.data);
                }else if(response.status == 404){
                    processingPolicy();
                }else{
                    errorPolicy();
                }
            }
        }catch(err){
            requestEnd = true;
            processingPolicy();
            // console.log(err);
            // toast.warning("Não foi possível recuperar os dados da apólice");
        }
    }, !policy && !processing && count <= MAX_ATTEMPS ? WAIT_TIME : null);


    // ==========================
    // Tratamento retorno EMISSAO
    // ==========================
    const successPolicy = dados => {
        setPolicy({
            nome: dados.insured.name,
            cpf: dados.insured.documentNumber,
            dt_nascimento: dados.insured.dateOfBirth,
            dia_vencimento: dados.policy.expirationDay,
            n_apolice: dados.policy.number,
            produto: {
                nome: dados.policy.productName,
            },
            dt_ini_vigencia: new Date(dados.policy.beginEffectiveDate),
            dt_fim_vigencia: new Date(dados.policy.endEffectiveDate),
        });
        carregarSVGs();
    }

    const processingPolicy = () => {
        if(count == MAX_ATTEMPS){
            // Exibe tela de Processamento
            setProcessing(true);
            carregarSVGs();
        }else{
            setCount(count+1);
        }
    }

    const errorPolicy = () => {
        // Desliga requests
        setProcessing(true);
        carregarSVGs();
    }

    const carregarSVGs = () => {
        lottie.loadAnimation({
            container: document.querySelector("#guido-floating"),
            animationData: GuidoFloating,
            renderer: 'svg',
            loop: true,
            autoplay: true,
        });

        lottie.loadAnimation({
            container: document.querySelector("#guido-floating2"),
            animationData: GuidoFloating,
            renderer: 'svg',
            loop: true,
            autoplay: true,
        });
    }

    return (
        <>
            {/* LOADING */}
            <Show if={!policy && !processing}>
                <h1>Estou muito feliz de ver você por aqui :)</h1>
                <p className="subtitle">Estamos ativando seu seguro, só um minutinho ;)</p>

                <div id="loading-animation"></div>
            </Show>

            {/* Encontrou apolice */}
            <Show if={policy}>
                <Container>     
                    <div className="d-inline-block">
                        <h1 className="mb-6 text-left anim-writing anim-typing">Bem vindo, {primeiroNome(policy?.nome)}.</h1>
                    </div>                                   
                    <div className="d-flex justify-content-center mb-6">
                        <Row className="container-m text-center">
                            <Col md="4">
                                <div id="guido-floating2"></div>
                            </Col>
                            <Col md="8" className="align-self-center">
                                <p className="h4 font-medium">
                                    Estamos felizes com a oportunidade de acompanhar a sua jornada.
                                </p>
                            </Col>
                        </Row>
                    </div>

                    <Row className="justify-content-between">
                        <Col sm="12" md="8">
                            <section className="seguro-item bg-bgColor mb-8">
                                <Row>
                                    <Col>
                                        <h2 className="mb-3">Resumo da Contratação</h2>

                                        <ul className="list-unstyled mb-3">
                                            <li><strong>Segurado:</strong> {policy?.nome}</li>
                                            <li><strong>CPF:</strong> <NumberFormat value={policy?.cpf} displayType="text" format="###.###.###-##" /></li>
                                            <li><strong>Idade:</strong> {idadeCompleta(policy?.dt_nascimento)}</li>                                            
                                        </ul>
                                        <p className="list-unstyled">
                                            <li><strong>Produto:</strong> {policy?.produto?.nome}</li>
                                            <li><strong>Apólice número:</strong> {policy?.n_apolice}</li>
                                            <li><strong>Data início de vigência:</strong> <Moment format="DD/MM/YYYY HH:mm:ss" date={policy?.dt_ini_vigencia} /></li>
                                            <li><strong>Data término de vigência:</strong> <Moment format="DD/MM/YYYY HH:mm:ss" date={policy?.dt_fim_vigencia} /></li>
                                            <li><strong>Dia de vencimento:</strong> {policy?.dia_vencimento}</li>
                                        </p>
                                    </Col>
                                </Row>
                            </section>
                        </Col>
                        <Col sm="12" md="4" className="ps-5 ps-xs-default menor mb-5">
                            <p>Acesse o app para utilizar o <b>Stories</b> e ter acesso a todos os <b>detalhes do seu seguro</b>.</p>
                            <div className="apps mb-4">
                                <a href="https://apps.apple.com/br/app/emotion-seguros/id1590052290" target="_blank">
                                    <Figure className="figure-stores mb-0">
                                        <img src={AppleStorePNG} alt="Google Play"/>
                                    </Figure>
                                </a>
                                <a href="https://play.google.com/store/apps/details?id=br.com.emotion.stories" target="_blank">
                                    <Figure className="figure-stores mb-0">
                                        <img src={GooglePlayPNG} alt="Apple Store"/>
                                    </Figure>
                                </a>    
                            </div>

                            <p className="pb-0">Caso tenha alguma dúvida entre em contato pelos nossos canais de atendimento.</p>
                            <ul className="no-disc">
                                <li><strong>WhatsApp: </strong><a href="https://wa.me/551138620085" target="_blank">11 3862-0085</a></li>
                                <li><strong>Telefone: </strong><a href="tel:0800-721-8508" target="_blank">0800 721 8508</a></li>                        
                                <li><strong>E-mail: </strong><a href="mailto:meajuda@emotion.com.br" target="_blank">meajuda@emotion.com.br</a></li>
                            </ul>                   
                        </Col>
                    </Row>                                 


                </Container>
            
            </Show>
        
            {/* Processando ou erro */}
            <Show if={processing}>
                <Container className="mainContent">
                    
                    <h1 className="mb-6">Estamos processando a sua contratação </h1>
                    <div className="d-flex justify-content-center mb-4">
                        <Row className="container-m text-center">
                            <Col md="4">
                                <div id="guido-floating"></div>
                            </Col>
                            <Col md="8" className="align-self-center">
                                <p className="h4 font-medium">
                                    Um e-mail será enviado assim que a contratação for finalizada.
                                </p>
                            </Col>
                        </Row>
                    </div>
                    <Row className="justify-content-md-center text-center">
                        <Col md="auto">
                            <Link to="/" className="btn btn-primary">VOLTAR PARA HOME</Link>
                        </Col>
                    </Row>
                </Container>
            </Show>
        </>
    );
}

export default DadosContrataco;