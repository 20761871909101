import { useEffect, useState } from "react";
import { Row, Col, Figure } from "react-bootstrap";
import Moment from "react-moment";
import { toast } from "react-toastify";
import apiEmissao from "../../../../services/apiEmissao";
import Show from "../../../../components/ShowIf";

// Assets
import CheckedWhiteIcon from '../../../../assets/icons/checked-white.png';
import DownloadFileWhiteIcon from '../../../../assets/icons/download-file-white.png';

const Seguro = props => {
    const { dados } = props;
    const { documentNumber } = props;
    const [ filePDF, setfilePDF ] = useState();

    useEffect(async ()=>{
        try {
            const response = await apiEmissao.post('/policy/getPolicyDocument', {
                documentNumber: documentNumber,
                policyNumber: dados.number
            });
            if (response.status == 200) {
                setfilePDF(response.data.data.url);
            };
        } catch (err) {
            return true;
        }
    },[]);

    return (
        <section className="seguro-item bg-bgColor mb-6">
            <Row>
                <Col sm="12" md="6">
                    <h2 className="mb-3">{dados.comercialName}</h2>
                    <ul className="list-unstyled">
                        <li><strong>Status:</strong> {dados.status == "VIG" ? "Ativo" : "Cancelado" } </li>
                        <li><strong>Segurado:</strong> {dados.policyHolder} </li>
                        <li><strong>Apólice:</strong> {dados.number} </li>
                        <li><strong>Vigência:</strong> de {<Moment format="DD/MM/YYYY" date={dados.beginEffectiveDate} />} a {<Moment format="DD/MM/YYYY" date={dados.endEffectiveDate} />} </li>
                        <li><strong>Dia de Vencimento:</strong> {dados.expirationDay} </li>
                    </ul>
                </Col>

                <Col sm="12" md="6" className="p-0 d-flex align-items-center">
                    <Row className="m-0 w-100">
                        {/* <Show if={filePDF}> */}
                            <Col className="text-center">
                                <h5 className="mb-3">Baixar Apólice</h5>
                                <a href={filePDF} target="_blank" className="din-link">
                                    <Figure className="roundIcon text-center special">
                                        <img src={DownloadFileWhiteIcon} />
                                    </Figure>
                                </a>
                            </Col>
                        {/* </Show> */}
                        <Col className="text-center">
                            <h5 className="mb-3">Condições Gerais</h5>
                            <a href="https://www.emotion.com.br/condicoes-gerais" target="_blank">
                                <Figure className="roundIcon">
                                    <img src={CheckedWhiteIcon} />
                                </Figure>
                            </a>
                        </Col>
                    </Row>
                </Col>              
            </Row>
        </section>
    );

}

export default Seguro;