import { useEffect, useRef } from "react";

/**
 * Chama uma determinada função a cada X tempo
 * @param {() => void} callback Função a ser executada
 * @param {number} delay Intervalo entre execuções
 */
const useInterval = (callback, delay) =>{
    const savedCallback = useRef(callback);

    // Remember the latest callback if it changes.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        // Don't schedule if no delay is specified.
        if (delay === null) return;

        const id = setInterval(() => savedCallback.current(), delay);

        return () => clearInterval(id);
    }, [delay])
}

export default useInterval;