import React from 'react';

const Show = (props) => {

    return ( 
        <>
            {props.if && props.children}
        </>
    );
}

export default Show;