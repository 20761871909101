import { useState } from "react";
import { Container, Form, Row, Col, FloatingLabel, Button, Spinner } from "react-bootstrap"
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import Auth from "../../auth";
import useLogged from "../../hooks/useLogged";
import apiAuth from "../../services/apiAuth";
import { validarCPF } from "../../utils";
import Show from "../../components/ShowIf";
import TokenInput from "../../components/TokenInput";

const Login = () => {
    const [loginInfo, setLogin] = useState({});
    const [loading, setLoading] = useState(false);
    const [stepForgetPassword, setStepForgetPassword] = useState({status: "0"});
    const [typeEyePassword, setTypeEyePassword] = useState(0);
    const [token, setToken] = useState('');
    const [validated, setValidated] = useState(false);
    const TOKEN_SIZE = 6;
    const history = useHistory();
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    let timeToast = 0;

    useLogged("/dashboard", true);


    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setLogin({...loginInfo, [name]: value});
    }

    const forgetPassword = (value) => {
        if (value === "0") setLogin({});
        setStepForgetPassword({status: value});
    }    

    const clearToken = () => {
        for( let i = 0; i < TOKEN_SIZE; i++ ) {
            document.getElementById("token-char-"+i).value = "";
        }
        setToken("");
    }

    const handleAuthenticate = async (event) => {
        try{
            setLoading(true);

            // Impede envio
            event.preventDefault();
            event.stopPropagation();

            if (!loginInfo.document || loginInfo.document.length != 11 || !loginInfo.password || loginInfo.password.length < 3) {
                setLoading(false);
                return controlToast("Por favor, preencha corretamente os campos de login");
            }             

            const response = await apiAuth.post('/authentication/authenticate', {
                documentNumber: loginInfo.document,
                password: loginInfo.password
            });

            setLoading(false);    
            if(response.status == 200){
                const sessionData = {
                    ...response.data.data,
                    documentNumber: loginInfo.document
                };

                Auth.saveSession(sessionData);
                // Redireciona para dashboard
                history.push("/dashboard");    
            }else{
                controlToast("Não foi possível realizar login. Por favor, tente novamente mais tarde");
            }    
        }catch(err){
            if (err.response && err.response.data.status == 404) {
                controlToast("O CPF ou a senha está incorreta. Por favor verifique");
            } else {
                controlToast("Não foi possível realizar esta operação. Por favor, tente novamente mais tarde");
            }
            setLoading(false);
        }
    }

    const recoverPassword = async (event) => {
        try {
            setLoading(true);

            // Impede envio
            event.preventDefault();
            event.stopPropagation();

            if (!loginInfo.documentNumber || loginInfo.documentNumber.length != 11) {
                setLoading(false);
                return controlToast("Por favor, preencha corretamente o campo CPF");
            }               

            const response = await apiAuth.post('/passwordRecovery/create', { documentNumber: loginInfo.documentNumber });

            setLoading(false);    
            if (response.status == 200) {
                forgetPassword("2");                   
            } else {
                controlToast("Não foi possível recuperar a senha. Por favor, tente novamente mais tarde");
            }    
        } catch(err) {
            setLoading(false);
            controlToast("Não foi possível recuperar a senha. Por favor, tente novamente mais tarde");
        }
    }

    const validateCode = async (event) => {     
        try {
            setLoading(true);

            // Impede envio
            event.preventDefault();
            event.stopPropagation();    
            
            // Valida tamanho do token
            if (!token || token.length != TOKEN_SIZE) {
                setLoading(false);
                clearToken();
                return controlToast(`Por favor, preencha todos os ${TOKEN_SIZE} campos do código`);
            } 

            const response = await apiAuth.post('/passwordRecovery/step10', { 
                documentNumber: loginInfo.documentNumber,
                entryCode: token
            });

            setLoading(false);    
            if (response.status === 200) {
                forgetPassword("3");                   
            } else {
                clearToken();
                if (response.status.message) {
                    controlToast(response.status.message);
                } else {
                    controlToast("Não foi possível validar o código. Por favor, tente novamente mais tarde");
                }
            }    
        } catch(err) {
            setLoading(false);
            clearToken();
            controlToast("Não foi possível validar o código. Por favor, tente novamente mais tarde");            
        }
    }    

    const alterPassword = async (event) => {     
        try {
            setLoading(true);
            const form = event.currentTarget;
            setValidated(true);            

            // Impede envio
            event.preventDefault();
            event.stopPropagation();

            if (form.checkValidity() === false || validatePassword(loginInfo.newPassword) === false) {
                setLoading(false);                
                document.getElementById('floatingPass').classList.add("manual-error");
                return controlToast("Por favor, preencha corretamente o campo NOVA SENHA");
            } else {
                document.getElementById('floatingPass').classList.remove("manual-error");
            }            
            
            const response = await apiAuth.post('/passwordRecovery/step20', { 
                documentNumber: loginInfo.documentNumber,
                password: loginInfo.newPassword
            });

            setLoading(false);    
            if (response.status == 200) {
                forgetPassword("0");
                setLogin({});

                toast.success("Senha alterada com sucesso!");
            } else {
                if (response.status.message) {
                    controlToast(response.status.message);
                } else {
                    controlToast("Não foi possível salvar a nova senha. Por favor, tente novamente mais tarde");
                }                
            }    
        } catch(err) {
            setLoading(false);
            controlToast("Não foi possível salvar a nova senha. Por favor, tente novamente mais tarde");
        }
    }

    const validateCPF = values => {
        if(values.value.length != 11) return true; // Caso nao tenha preenchido tudo

        const valido = validarCPF(values.formattedValue);
        if (!valido) controlToast("CPF inválido, por favor verifique");

        return valido;
    }    

    const validatePassword = (values) => {
        return strongRegex.test(values);
    }    
    
    const controlToast = (msg) => {
        if (timeToast == 0) {
            // contrl. para o mesmo toast ñ ser exibido mais de uma vez
            timeToast = 1;
            setTimeout(() => timeToast = 0, 5000);            
            return toast.warning(msg);
        } else {
            return true;
        }        
    }      

    const eyePassword = () => {
        let eye = document.querySelector('.lnr-eye');
        let input = document.querySelector('.eye-pass');

        if (typeEyePassword === 0) {
            setTypeEyePassword(1);
            input.setAttribute('type', 'text');
            
            eye.classList.add("icon-eye-close");        
            eye.classList.remove("icon-eye-open");
        } else {
            setTypeEyePassword(0);
            input.setAttribute('type', 'password');

            eye.classList.add("icon-eye-open");        
            eye.classList.remove("icon-eye-close");
        }     
    }

    return (
        <Container className="mainContent">
            {/* Login */}
            <Show if={stepForgetPassword.status === "0"}>
                <h1 className="mb-6">Login</h1>
                <Form noValidate onSubmit={handleAuthenticate}>
                    <Row className="justify-content-md-center text-center">
                        <Col md="6">
                            <FloatingLabel controlId="floatingDocument" label="CPF" className="col-md-12 mb-4" >
                                {/* <Form.Control required type="text" name="document" placeholder="CPF" onChange={handleInputChange} /> */}
                                <NumberFormat
                                    required
                                    name="document"
                                    placeholder="CPF"
                                    className="form-control"
                                    format="###.###.###-##"
                                    isAllowed={validateCPF}
                                    onValueChange={({value}) => handleInputChange({target: {name: "document", value: value}})}
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center text-center">
                        <Col md="6">
                            <FloatingLabel controlId="floatingPass" label="Senha" className="col-md-12 mb-5" >
                                <Form.Control autoComplete="off" type="password" name="password" className="eye-pass" placeholder="Senha" required onChange={handleInputChange} />
                                <span onClick={eyePassword} class="icon-eye-open lnr-eye"></span>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center text-center mb-5">
                        <Col md="6">
                            <a onClick={() => forgetPassword("1")} className="on-click" >Esqueci minha senha</a>
                        </Col>
                    </Row>                
                    <Row className="justify-content-md-center text-center">
                        <Col md="4">
                            <Button variant="primary" type="submit" disabled={loading}>                            
                                {loading ? <Spinner animation="grow" /> :  "Conectar" }                            
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Show>
            
            {/* Esqueci minha senha */}
            <Show if={stepForgetPassword.status === "1"}>
                <h1 className="mb-6">Recuperar senha</h1>
                <Form noValidate onSubmit={recoverPassword}>
                    <Row className="justify-content-md-center text-center">
                        <Col md="6">
                            <FloatingLabel controlId="floatingDocument" label="CPF" className="col-md-12 mb-5" >
                                {/* <Form.Control required type="text" name="document" placeholder="CPF" onChange={handleInputChange} /> */}
                                <NumberFormat
                                    required
                                    name="documentNumber"
                                    placeholder="CPF"
                                    className="form-control"
                                    format="###.###.###-##"
                                    isAllowed={validateCPF}
                                    onValueChange={({value}) => handleInputChange({target: {name: "documentNumber", value: value}})}
                                />
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center text-center">
                        <Col md="3">
                            <Button variant="primary fluid" type="submit" disabled={loading}>                            
                                {loading ? <Spinner animation="grow" /> :  "Enviar" }
                            </Button>
                        </Col>
                        <Col md="2">
                            <a onClick={() => forgetPassword("0")} className="btn btn-primary white fluid-slim no-shadow">Voltar</a>
                        </Col>
                    </Row>
                </Form>
            </Show>

            {/* Código para recuperação */}
            <Show if={stepForgetPassword.status === "2"}>
                <h1 className="mb-6">Por favor, informe o código que enviamos para seu email</h1>
                <Form noValidate onSubmit={validateCode}>
                    <Row className="justify-content-md-center text-center">
                        <Col className="text-center mb-5">
                            <TokenInput size={TOKEN_SIZE} onTokenReady={setToken} />
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center text-center">
                        <Col md="3">
                            <Button variant="primary fluid" type="submit" disabled={loading}>                            
                                {loading ? <Spinner animation="grow" /> :  "Enviar" }
                            </Button>
                        </Col>
                        <Col md="2">
                            <a onClick={() => forgetPassword("0")} className="btn btn-primary white fluid-slim no-shadow">Voltar</a>
                        </Col>
                    </Row>
                </Form>
            </Show>            

            {/* Alteração de senha */}
            <Show if={stepForgetPassword.status === "3"}>
                <h1 className="mb-6">Digite sua nova senha</h1>
                <Form noValidate validated={validated} onSubmit={alterPassword}>                    
                    <Row className="justify-content-md-center text-center">
                        <Col md="6">
                            <FloatingLabel controlId="floatingPass" label="Nova senha" className="col-md-12 mb-5">
                                <Form.Control autoComplete="off" required type="password" name="newPassword" className="eye-pass" placeholder="Nova senha" onChange={handleInputChange} />
                                <span onClick={eyePassword} class="icon-eye-open lnr-eye"></span>

                                <p className="legenda">
                                    Mínimo 8 caracteres, contendo:
                                    <ul>
                                        <li>Uma letra maiúscula;</li>
                                        <li>Uma minúscula;</li>
                                        <li>Um número;</li>
                                        <li>Um caracter especial</li>
                                    </ul>
                                </p>                                
                            </FloatingLabel>
                        </Col>
                    </Row>                    

                    <Row className="justify-content-md-center text-center">
                        <Col md="4">
                            <Button variant="primary" type="submit" disabled={loading}>                            
                                {loading ? <Spinner animation="grow" /> :  "Salvar" }
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Show>
        </Container>
    );

}

export default Login;