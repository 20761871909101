import { useState } from "react";
import { Form, Row, FloatingLabel, Container, Button, Col } from "react-bootstrap";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import Show from "../../../../components/ShowIf";
import { validarCPF } from "../../../../utils";

/**
 * 
 * @param {{
 *     show: boolean
 *     onSend: Function
 *     email: String
 * }} props 
 * @returns 
 */
const DadosIniciais = (props) => {
    const [dados, setDados] = useState({});
    // if (props.email) dados.email = props.email;

    const [validated, setValidated] = useState(false);
    const [typeEyePassword, setTypeEyePassword] = useState(0);
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
    let timeToast = 0;

    const handleStep = (event) => {
        const form = event.currentTarget;        
        setValidated(true);

        // Impede envio
        event.preventDefault();
        event.stopPropagation();

        if (form.checkValidity() === false) {
            return controlToast("Por favor, preencha corretamente o formulário");
        } else if (validatePassword(dados.senha) === false) {
            document.getElementById('floatingPassword').classList.add("manual-error");
            return controlToast("Por favor, preencha corretamente o campo SENHA");
        } else {
            document.getElementById('floatingPassword').classList.remove("manual-error");
        }

        // Direciona para o proximo passo 
        props.onSend(dados);
    }

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setDados({...dados, [name]: value});
    }

    const validateCPF = (values) => {
        if(values.value.length != 11) return true; // Caso nao tenha preenchido tudo

        const valido = validarCPF(values.formattedValue);
        if(!valido) toast.warning("CPF inválido");

        return valido;
    }

    const validatePassword = (values) => {
        return strongRegex.test(values);
    }

    const controlToast = (msg) => {
        if (timeToast == 0) {
            // contrl. para o mesmo toast ñ ser exibido mais de uma vez
            timeToast = 1;
            setTimeout(() => timeToast = 0, 5000);            
            return toast.warning(msg);
        } else {
            return true;
        }        
    }

    const eyePassword = () => {
        let eye = document.querySelector('.lnr-eye');
        let input = document.querySelector('.eye-pass');        

        if (typeEyePassword === 0) {
            setTypeEyePassword(1);
            input.setAttribute('type', 'text');
            
            eye.classList.add("icon-eye-close");        
            eye.classList.remove("icon-eye-open");
        } else {
            setTypeEyePassword(0);
            input.setAttribute('type', 'password');

            eye.classList.add("icon-eye-open");        
            eye.classList.remove("icon-eye-close");
        }     
    }    
    
    return (
        <Show if={props.show}>

            <Container className="mainContent">
                <Row>
                    <Col xs>
                        <h1 className="mb-6">Ei, chega junto!</h1>
                    </Col>
                </Row>

                <Form noValidate validated={validated} onSubmit={handleStep}>
                    <Row>
                        <FloatingLabel controlId="floatingCPF" label="CPF" className="col-sm-6 mb-4" >
                            <NumberFormat
                                required
                                value={dados.cpf}
                                name="cpf"
                                placeholder="000.000.000-00"
                                className="form-control"
                                format="###.###.###-##"
                                isAllowed={validateCPF}
                                onValueChange={({formattedValue}) => handleInputChange({target: {name: "cpf", value: formattedValue}})}
                            />
                        </FloatingLabel>

                        <FloatingLabel controlId="floatingEmail" label="E-mail" className="col-sm-6 mb-4" >
                            <Form.Control required value={dados.email} type="email" name="email" placeholder="exemplo@mail.com" onChange={handleInputChange} />
                        </FloatingLabel>
                    </Row>

                    <Row className="mb-6">
                        <FloatingLabel controlId="floatingTel" label="DDD + Celular" className="col-sm-6 mb-4" >
                            <NumberFormat
                                required
                                value={dados.tel}
                                name="tel"
                                placeholder="(11) 91111-1111"
                                className="form-control"
                                format="(##) #####-####"
                                onValueChange={({formattedValue}) => handleInputChange({target: {name: "tel", value: formattedValue}})}
                            />
                        </FloatingLabel>
                        
                        <FloatingLabel controlId="floatingPassword" label="Senha" className="col-sm-6">
                            <Form.Control required type="password" name="senha" className="eye-pass" placeholder="Senha" onChange={handleInputChange} />
                            <span onClick={eyePassword} class="icon-eye-open lnr-eye space"></span>

                            <p className="legenda">
                                Mínimo 8 caracteres, contendo:
                                <ul>
                                    <li>Uma letra maiúscula;</li>
                                    <li>Uma minúscula;</li>
                                    <li>Um número;</li>
                                    <li>Um caracter especial</li>
                                </ul>
                            </p>
                        </FloatingLabel>
                    </Row>

                    <Row className="justify-content-sm-center text-center">
                        <Col sm="auto">
                            <Button variant="primary" type="submit">
                                Enviar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>

        </Show>
    );
}

export default DadosIniciais;