import { useState } from "react";
import { Form, Row, FloatingLabel, Container, Button, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import NumberFormat from 'react-number-format';
import moment from "moment";
import Show from "../../../../components/ShowIf";

/**
 * 
 * @param {{
 *     show: boolean
 *     onSend: Function
 * }} props 
 * @returns 
 */
const DadosCartao = (props) => {
    const [dados, setDados] = useState({});
    const [validated, setValidated] = useState(false);
    const DIA_VENCIMENTO = moment().date();

    // HANDLES
    const handleStep = (event) => {
        const form = event.currentTarget;
        setValidated(true);

        // Impede envio
        event.preventDefault();
        event.stopPropagation();

        if (dados.codigo && dados.dataCartao && dados.nome && dados.numeroCartao && dados.aceiteCondicoes) {
            if (dados.dataCartao) {
                const verCartao = dados.dataCartao.trim();
                const [verCartaoMes, verCartaoAno] = verCartao.split("/");
    
                if (verCartaoAno.length != 4) {
                    return toast.warning("Por favor, preencha o ANO do cartão com 4 dígitos");
                } else if (form.checkValidity() === false) {
                    return toast.warning("Por favor, preencha corretamente o formulário");
                }
            }
            
            // Direciona para o proximo passo 
            props.onSend(dados);            
        } else {
            return toast.warning("Por favor, preencha corretamente o formulário");
        }
    }

    const handleInputChange = (event) => {
        const {name, type, checked} = event.target;
        const value = type === 'checkbox' ? checked : event.target.value;
        setDados({...dados, [name]: value});
    }

    // HELP FUNCTIONS
    const validateDate = values => {
        if(values.value.length != 6) return true; // Caso nao tenha preenchido tudo

        const [mes, ano] = values.formattedValue.split("/");
        const dataAtual = new Date();
        const valido = (Number(ano) == dataAtual.getFullYear() && Number(mes) > dataAtual.getMonth()+1 ) // Vence no mesmo ano, mas no futuro
            || (Number(ano) >  dataAtual.getFullYear() && Number(mes) > 0 && Number(mes) <= 12 ) // Vence no futuro

        if(!valido) toast.warning("Data deverá ser futura");

        return valido;
    }
    
    return (
        <Show if={props.show}>

            <Container className="mainContent">
                <Row>
                    <Col xs>
                        <h1 className="mb-6 menor">Última etapa!<br/>Por favor, informe os dados do pagamento.</h1>
                    </Col>
                </Row>

                <Form noValidate validated={validated} onSubmit={handleStep}>
                    <Row>
                        <FloatingLabel controlId="floatingNumeroCartao" label="Número do cartão" className="col-md-12 mb-4" >
                            {/* <Form.Control required type="text" name="numeroCartao" placeholder="Número do cartão" onChange={handleInputChange} /> */}
                            <NumberFormat
                                required
                                name="numeroCartao"
                                autoComplete="off"
                                placeholder="Número do cartão"
                                className="form-control"
                                format="#### #### #### ####"
                                onValueChange={({value}) => handleInputChange({target: {name: "numeroCartao", value}})}
                            />
                        </FloatingLabel>
                    </Row>

                    <Row>
                        <FloatingLabel controlId="floatingNome" label="Nome (igual no cartão)" className="col-md-12 mb-4" >
                            <Form.Control autoComplete="off" required type="text" name="nome" placeholder="Nome (igual no cartão)" onChange={handleInputChange} />
                        </FloatingLabel>
                    </Row>

                    <Row>
                        <FloatingLabel controlId="floatingDataCartao" label="Mês/Ano" className="col-md-6 mb-4" >
                            <NumberFormat
                                required
                                name="dataCartao"
                                placeholder="Mês/Ano"
                                className="form-control"
                                format="##/####"
                                isAllowed={validateDate}
                                onValueChange={({formattedValue}) => handleInputChange({target: {name: "dataCartao", value: formattedValue}})}
                            />
                        </FloatingLabel>
                        
                        <FloatingLabel controlId="floatingCodigo" label="Código" className="col-md-6 mb-4">
                            <Form.Control autoComplete="off" required type="text" name="codigo" placeholder="Código" maxLength={4} onChange={handleInputChange} />
                        </FloatingLabel>
                    </Row>

                    <div className="mb-5">
                        <p className="menor">
                            Iremos validar o seu cartão enviando uma cobrança de R$1,00.<br/>
                            Não se preocupe que iremos devolver.<br/>
                            O dia de vencimento do seu seguro será todo dia: {DIA_VENCIMENTO}
                        </p>
                    </div>

                    <Row className="mb-6">
                        <Form.Check type="checkbox">
                            <Form.Check.Input type="checkbox" required name="aceiteCondicoes" checked={dados.aceiteCondicoes} onChange={handleInputChange} />
                            <Form.Check.Label className="color-mainFont">
                                Declaro que li e aceito as <a href="https://www.emotion.com.br/condicoes-gerais" target="_blank">condições gerais do seguro</a>
                            </Form.Check.Label>
                        </Form.Check>
                    </Row>
                    {/* <Row className="mb-6">
                        <Form.Check type="checkbox" >
                            <Form.Check.Input type="checkbox" required name="aceiteTermos" checked={dados.aceiteTermos} onChange={handleInputChange} />
                            <Form.Check.Label className="color-mainFont">
                                Declaro que li e aceito os <a href="https://www.emotion.com.br/termos-de-uso" target="_blank">termos de uso</a> do aplicativo
                            </Form.Check.Label>
                        </Form.Check>
                    </Row> */}

                    <Row className="justify-content-md-center text-center">
                        <Col md="auto">
                            <Button variant="primary" type="submit">
                                Contratar
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Container>
            
        </Show>
    );
}

export default DadosCartao;