import { useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import './styles.scss';

/**
 * 
 * @param {{
 *     size: number
 *     onTokenReady: Function
 *     secret?: boolean
 * }} props 
 * @returns 
 */
const TokenInput = props => {

    const [token, setToken] = useState([]);
    const inputsRefs = [];

    const handleChangeToken = (i, value) => {
        token[i] = value;
        setToken(token);

        if(i+1 < inputsRefs.length){
            inputsRefs[i+1].focus();
        }else{
            // Final
            // Valida se esta completo
            if(token.length != props.size) return toast.warning("Insira todos os caracteres");

            props.onTokenReady(token.join(''));
        }
    }

    const buildInputs = () => {
        const inputs = [];

        for(let i = 0; i < props.size; i++){
            inputs.push( <Form.Control 
                    ref={(input) => { inputsRefs[i] = input; }} 
                    key={"input-token-"+i}
                    maxLength="1"
                    autoComplete="off"
                    required
                    type={props.secret ? "password" : "number"}
                    id={'token-char-'+i}
                    name={'token-char-'+i}
                    onChange={(e) => handleChangeToken(i, e.target.value)} /> )
        }

        return inputs;
    }

    return (
        <section className="tokenInput">
            {buildInputs()}
        </section>
    );
}

export default TokenInput;