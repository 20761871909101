import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Cadastro from './pages/Cadastro';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import DadosContrataco from './pages/DadosContratacao';
import Home from './pages/Home';
import LayoutPage from './pages/LayoutPage';
import Login from './pages/Login';
import NotFoundPage from './pages/NoFoundPage';

const PagesRoutes = () => {
    return(
        <Router>
            <LayoutPage>
                
                <Switch>
                    <Route exact path="/" component={Home}>
                        <Redirect to="/onboarding" />
                    </Route>
                    <Route path="/onboarding" component={Home} />
                    <Route path="/cadastro" component={Cadastro} />
                    <Route path="/dados-contratacao" component={DadosContrataco} />
                    <Route path="/login" component={Login} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/profile" component={Profile} />

                    <Route path="*" component={NotFoundPage}/>
                </Switch>
                
            </LayoutPage>
        </Router>
    );
}

export default PagesRoutes;