import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Fade } from "react-bootstrap";
import api from "../../services/api";
// import VerificaConvite from "./components/VerificaConvite";
import DadosIniciais from "./components/DadosIniciais";
import VerificaToken from "./components/VerificacaoToken";
import DadosCartao from "./components/DadosCartao";

const Cadastro = () => {

    const [firstStep, setFirstStep] = useState({});
    // const [inviteStep, setinviteStep] = useState({});
    const [token, setToken] = useState('');
    const [step, setStep] = useState(1);
    const history = useHistory();

    useEffect(() => window.scrollTo(0, 0));

    // Handles
    /**
     * Verifica convite
     * @param {{
     *      convite: string
     * }} dados 
     */
    // const handleZeroStep = async (dados) => {
    //     try {
    //         const response = await api.post('/onboarding/step05', { 
    //             inviteNumber: dados.convite 
    //         });

    //         if (response.status == 200) {
    //             inviteStep.convite = dados.convite;
    //             inviteStep.email = response.data.data.email;
    //             setStep(1); // Inicia o cadastro
    //         } else {
    //             if (response.status == 404) {
    //                 toast.warning(response.message);
    //             } else {
    //                 toast.warning("Não foi possível validar seu convite. Por favor, tente novamente mais tarde");
    //             }                
    //         };
    //     } catch (err) {
    //         if (err.response && err.response.data.status == 404) {
    //             toast.warning(err.response.data.message);
    //         } else {
    //             toast.warning("Não foi possível validar seu convite. Por favor, tente novamente mais tarde");
    //         }
    //     }
    // }

    /**
     * Realiza primeira etapa da contratação
     * @param {{
     *      cpf: string,
     *      email: string,
     *      mobile: string,
     *      password: string
     * }} dados 
     */
    const handleFirstStep = async (dados) => {
        setFirstStep(dados);

        try {
            const response = await api.post('/onboarding/step10', {
                cpf: dados.cpf,
                email: dados.email,
                mobile: dados.tel,
                password: dados.senha
            });

            if (response.status == 200) {
                // Apresenta validação de token              
                setStep(2);
            } else {
                toast.warning("Não foi possível completar esta etapa. Por favor, tente novamente mais tarde");
            };

        } catch (err) {
            if (err.response && err.response.data.status == 404) {
                toast.warning(err.response.data.message);
            } else {
                toast.warning("Não foi possível realizar esta operação. Por favor, tente novamente mais tarde");
            }
        }
    }

    /**
     * Verifica se o token em questão é valido
     * @param {string} token 
     */
    const handleTokenStep = async (token) => {
        setToken(token);

        try{
            const response = await api.post('/onboarding/step20', {
                cpf: firstStep.cpf,
                entryCode: token
            });

            if(response.status == 200){
                // Apresenta validação de token
                setStep(3);

            }else{
                toast.warning("Não foi possível completar esta etapa. Por favor, tente novamente mais tarde");
            }

        }catch(err){
            toast.warning("Não foi possível realizar esta operação. Por favor, tente novamente mais tarde");
        }
    }
    
    /**
     * Realiza validação de cartão
     * @param {{
     *      numeroCartao: string,
     *      nome: string,
     *      dataCartao: string,
     *      codigo: string
     * }} dados 
     */
    const handleCardStep = async (dados) => {

        try{
            const response = await api.post('/onboarding/step30', {
                cpf: firstStep.cpf,
                // inviteNumber: inviteStep.convite,
                frmPgm: {
                    tip: "cac",
                    dados:{
                        cc_name: dados.nome,
                        num: dados.numeroCartao,
                        validade: dados.dataCartao,
                        cvv: dados.codigo
                    }
                }
            });

            if(response.status == 200){
                // Dados do cartão não serão salvos no state
                history.push("/dados-contratacao", {
                    cpf: firstStep.cpf,
                    anchor: "top"
                });

            }else{
                toast.warning("Não foi possível completar esta etapa. Por favor, tente novamente mais tarde");
            }

        }catch(err){
            toast.warning("Não foi possível realizar esta operação. Por favor, tente novamente mais tarde");
        }
    }

    const goBack = (step) => {
        setStep(step);
    }

    return (
        <section className="container-m">
            {/* Parte 0 - Convite - Etapa removida */}
            {/* <Fade in={step === 0}>
                <VerificaConvite show={step === 0} onSend={handleZeroStep} />
            </Fade> */}

            {/* Parte 1 */}
            <Fade in={step === 1}>
                {/* <DadosIniciais show={step === 1} onSend={handleFirstStep} email={inviteStep.email} /> */}
                <DadosIniciais show={step === 1} onSend={handleFirstStep} />
            </Fade>

            {/* Parte 2 */}
            <Fade in={step === 2}>
                <VerificaToken show={step === 2} onSend={handleTokenStep} onReenviar={()=> handleFirstStep(firstStep)} email={firstStep.email} voltar={goBack} />
            </Fade>

            {/* Parte 3 */}
            <Fade in={step === 3}>
                <DadosCartao show={step === 3} onSend={handleCardStep} />
            </Fade>

        </section>
    );
}

export default Cadastro;