import Menu from "../../components/Menu";

const LayoutPage = props => {
    return (
        <main>
            <header>
                <Menu />
            </header>

            <div className="container">
                {props.children}
            </div>
        </main>
    );
}

export default LayoutPage;