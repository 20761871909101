// import logo from './logo.svg';
import './styles/tema.scss';
import PagesRoutes from './routes';
import { ToastContainer } from 'react-toastify';

const App = () => (
  <>
    <PagesRoutes />
    <ToastContainer theme={'colored'}/>
  </>
);

export default App;
